<template>
  <form-miembro :miembroId="$route.params.id"></form-miembro>
</template>

<script>
import FormMiembro from "@/components/equipo/formMiembro.vue";
export default {
  components: {
    FormMiembro,
  },
};
</script>

<style></style>
